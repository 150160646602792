<template>
    <div class="form-box card-container popbox-container" id="form-popup">
        <div class="columns">
            <div class="column">
                <expander :title="$store.getters.getReportDetails.columnDefination.data.description" @hasExpanded="handleDataPassed">
                    <template v-slot:content>
                        <div v-if="isDropdownExpanded" class=" has-text-black" :class="{ hidden: doHideWelcome }">
                            <div class="" :style="{ padding: '20px' }">
                                <div class="banner-container-new">


                                    <justLable lableName="Package :"
                                        :lableValue="reportsDetails.columnDefination.data.bundle || 'NA'"
                                        icon="workflow.svg">
                                    </justLable>
                                    <justLable lableName="Name :"
                                        :lableValue="reportsDetails.columnDefination.data.display_name || 'NA'"
                                        icon="workflow instace.svg">
                                    </justLable>                                    
                                </div>
                            </div>
                        </div>
                    </template>
                </expander>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <nav class="navbar" role="navigation" aria-label="main navigation"
                    style="background-color: hsl(0deg, 0%, 100%);border-radius: 23px">
                    <div id="navbarBasicExample" class="navbar-menu">
                        <div class="navbar-start">
                            <div class="navbar-item">

                                <b-dropdown  aria-role="list">

                                    <template #trigger>
                                        <b-button label="Export to" type="is-primary is-rounded" icon-left="download"
                                            />
                                    </template>

                               


                                    <b-dropdown-item  aria-role="listitem" @click="downloadCsv">
                                        <div class="media">
                                            <span class="icon">
                                                <img src="../../src/assets/csv.png" />
                                            </span>
                                            <span>Download CSV</span>
                                            
                                        </div>
                                    </b-dropdown-item>
                                    <b-dropdown-item  aria-role="listitem" @click="downloadExle">
                                        <div class="media">
                                            <span class="icon">
                                                <img src="../../src/assets/xls-file.png" />
                                            </span>
                                            <span>Download Excel</span>
                                            
                                        </div>
                                    </b-dropdown-item>
                                    <b-dropdown-item  aria-role="listitem" @click="downloadPdf">
                                        <div class="media">
                                            <span class="icon">
                                                <img src="../../src/assets/pdf.png" />
                                            </span>
                                            <span>Download PDF</span>
                                            
                                        </div>
                                    </b-dropdown-item>

                                    
                                </b-dropdown>

                            </div>
                        </div>

                        <div class="navbar-end">

                            <div class="navbar-item">
                                <b-tooltip label="Clear Filter">
                                    <button label="Clear" class="button is-small is-white" @click="clearAllFilter">
                                        <span class="icon is-small">
                                            <span class="icon">
                                                <img src="../assets/filter.png">
                                            </span>
                                        </span>
                                    </button>
                                </b-tooltip>
                            </div>
                        </div>
                    </div>
                </nav>
                <div ref="table"></div>
            </div>
        </div>

    </div>
</template>
<script>
import { globalFunction } from "@/services/global.function";
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import justLable from "./justLable.vue";
import expander from './expander.vue';
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import * as XLSX from 'xlsx/xlsx.mjs';

export default {
    data() {
        return {
            isLoading: false,
            formData: {},
            formKey: "",
            isFormDirty: false,
            doHideWelcome: false,
            isDropdownExpanded: false,
            reportsDetails: null
        };
    },
    async beforeCreate() { },
    components: {
        DataTable,
        Column,
        justLable,
        expander,
        VueBottomSheet
    },
    props: {},
    created() {

    },
    mounted() {
        this.init();

    },
    computed: {

    },
    watch: {},
    methods: {
        async init() {
            this.reportsDetails = await this.$store.getters.getReportDetails
            console.log("reports detils", this.reportsDetails.reportData.data, this.reportsDetails.columnDefination.data.column_definition)

            this.tabulator = new Tabulator(this.$refs.table, {
                layout: "fitDataStretch",
                data: this.reportsDetails.reportData.data, //link data to table
                reactiveData: true, //enable data reactivity
                columns: this.reportsDetails.columnDefination.data.column_definition, //define table columns
            });
        },
        async downloadCsv() {
            this.tabulator.download("csv", "data.csv");
        },
        async downloadExle() {
            this.tabulator.download("xlsx", "data.xlsx", { sheetName: "My Data" });
        },
        async downloadPdf() {
            this.tabulator.download("pdf", "data.pdf", {
                orientation: "landscape", //set page orientation to portrait
                title: "Report", //add title to report
            });
        },
        async clearAllFilter() {
            this.tabulator.clearHeaderFilter();
        },
        handleDataPassed(data) {
            this.isDropdownExpanded = data;
        },

    },
    destroyed() {
        this.$root.$emit("close_form");
    }
};
</script>
<style lang="scss">
.tabulator {
    position: relative;
    border: none;
    background-color: #eaecef;
    /* border-radius: 20px; */
    font-size: 14px;
    padding-top: 28px;
    top: -26px;
    text-align: left;
    overflow: hidden;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    transform: translateZ(0);
}

.tabulator .tabulator-header .tabulator-col {
    display: inline-flex;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: flex-start;
    border-right: none;
    background: #e9ecef;
    text-align: left;
    vertical-align: bottom;
    overflow: hidden;
}

.tabulator .tabulator-header {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    border-bottom: none;
    background-color: #e6e6e6;
    color: #555;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

.tabulator-row .tabulator-cell {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    padding: 7px;
    border-right: none;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-top: 1px solid #d2d0d0;
}

.tabulator-row.tabulator-row-even {
    background-color: transparent;
}

.tabulator-header-filter>input {
    border: 1px solid lightgray;
    border-radius: 5px;
}
</style>
  